<template>
    <div
        :class="['wit-select', `wit-select__${size}`, {'wit-select__inline': inline}]"
        :data-selected="internalValue && internalValue.label ? internalValue.label : internalValue"
    >
        <v-select
            v-model="internalValue"
            :label="label"
            :getOptionLabel="customLabel"
            :placeholder="placeholder"
            :options="notReady ? [{[label]: notReadyMessage}] : options"
            :selectable="() => !notReady"
            :disabled="disabled"
            :reduce="reduce"
            :clearable="allowEmpty"
            :append-to-body="!appendToParent"
            v-bind="conditionalProps"
            @input="emitInput"
            @open="$emit('open')"
        >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
                ><slot :name="slot" v-bind="scope"
            /></template>
            <template v-if="subLabel" v-slot:option="option">
                <div class="wit-select__option--sub-label">{{ option[subLabel] }}</div>
                <div>{{ option[label] }}</div>
            </template>
        </v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {fromEntries} from '@/shared/fromEntries'

export default {
    components: {
        vSelect,
    },
    props: {
        value: {
            required: true,
        },
        options: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        customLabel: {
            type: Function,
        },
        preselectFirst: {
            type: Boolean,
        },
        subLabel: {
            type: String,
        },
        subtractSubLabel: {
            type: Boolean,
        },
        reduce: {
            type: Function,
            default: option => option,
        },
        size: {
            type: String,
            default: 'md',
            // 'lg', 'md', 'sm'
        },
        inline: {
            type: Boolean,
            default: false,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        calculatePosition: {
            type: Function,
        },
        filterBy: {
            type: Function,
        },
        notReady: {
            type: Boolean,
        },
        notReadyMessage: {
            type: String,
        },
        appendToParent: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value() {
            this.internalValue = this.value
        },
        options() {
            if (this.preselectFirst && this.options.length > 0) {
                this.internalValue = this.options[0]
                this.emitInput()
            }
        },
    },
    created() {
        if (this.value) {
            this.internalValue = this.value
        }
        if (this.preselectFirst && this.options.length > 0) {
            this.internalValue = this.options[0]
            this.emitInput()
        }
    },
    data() {
        return {
            internalValue: null,
        }
    },
    computed: {
        conditionalProps() {
            let props = {}

            if (this.filterBy) {
                props.filterBy = this.filterBy
            }
            if (this.subLabel) {
                props.filterBy = this.filterBy || this.subSearch
            }
            if (this.calculatePosition) {
                props.calculatePosition = this.calculatePosition
            }

            return props
        },
    },
    methods: {
        emitInput() {
            if (!this.internalValue) {
                this.$emit('clear')
            }

            if (this.subLabel && this.subtractSubLabel) {
                const entries = Object.entries(this.internalValue)
                const filtered = entries.filter(([key, value]) => {
                    return key !== this.subLabel
                })
                this.$emit('input', fromEntries(filtered))
            } else {
                this.$emit('input', this.internalValue)
            }
        },
        subSearch(option, label, search) {
            return (
                label.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                option[this.subLabel].toLowerCase().indexOf(search.toLowerCase()) > -1
            )
        },
    },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
@import '@/assets/scss/_variables.scss';

.wit-select {
    width: 100%;
}
.v-select {
    min-width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #fff;
}
.vs__search::placeholder {
    color: #adadad;
}
.vs__selected-options {
    padding-left: 0;

    .vs__selected {
        color: #3e515b;
        margin-left: 0;
        border: none;
    }
    .vs__search {
        margin-left: 0;
        border: none;
    }
}
.vs__dropdown-toggle {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 0;
}
.vs__actions {
    .vs__clear {
        padding-bottom: 1.5px;
    }
    .vs__open-indicator {
        transform: scale(0.9);
    }
}
.vs__dropdown-option {
    padding-top: 7px;
    padding-bottom: 7px;
}
.vs__dropdown-option--highlight,
.vs__dropdown-option--selected:hover {
    background: $brand-color-miami-blue;
}
.vs__dropdown-option--selected {
    background: $brand-color-miami-blue;
    opacity: 0.5;
    color: white;
}
.vs__dropdown-menu {
    min-width: 100px;
    z-index: 3003;
}
.wit-select__option--sub-label {
    opacity: 0.8;
    font-size: 0.65rem;
    margin: 0;
    line-height: 0.6rem;
}

.v-select > div {
    height: 100%;
}
.wit-select__md {
    font-size: 0.875rem;
    .vs__selected-options {
        min-height: 48px;
        .vs__selected,
        .vs__search {
            margin: 0;
            padding: 10px 15px;
            line-height: 1.5;
        }

        .vs__search {
            position: absolute;
            // min-height: calc(1.5em + 0.75rem);
            min-height: 48px;
            width: 100%;
        }
    }

    .vs--disabled {
        .vs__search {
            display: none;
        }
    }

    .vs--open {
        .vs__selected-options {
            height: 48px;
            .vs__selected {
                position: relative;
                opacity: 0;
            }
        }
    }
}
.wit-select__sm {
    font-size: 0.76562rem;
    .vs__selected-options {
        min-height: calc(1.5em + 0.5rem + 2px);
        .vs__selected,
        .vs__search {
            margin: 0;
            padding: 0.25rem 0.5rem;
            line-height: 1.5;
        }

        .vs__search {
            position: absolute;
            min-height: calc(1.5em + 0.5rem + 2px);
            width: 100%;
        }
    }

    .vs--disabled {
        .vs__search {
            display: none;
        }
    }

    .vs--open {
        .vs__selected-options {
            height: calc(1.5em + 0.5rem + 2px);
            .vs__selected {
                position: relative;
                opacity: 0;
            }
        }
    }
}
.wit-select__inline {
    display: inline-block;
}
</style>
