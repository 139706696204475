import axios from 'axios'

const state = {
    choicesFields: [],
    choicesIterators: {},
}

const getters = {
    choicesFields: state => state.choicesFields,
    choicesIterators: state => state.choicesIterators,
}

const actions = {
    async getChoices({commit}) {
        const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/workflow/choices`)

        commit('SET_CHOICES_FIELDS', response.data.data.fields)
        commit('SET_CHOICES_ITERATORS', response.data.data.operators)
    },
}

const mutations = {
    SET_CHOICES_FIELDS(state, choicesFields) {
        state.choicesFields = choicesFields
    },

    SET_CHOICES_ITERATORS(state, choicesIterators) {
        state.choicesIterators = choicesIterators
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
