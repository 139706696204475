export const initCookiebotScript = () => {
    // Define dataLayer and the gtag function.
    window.dataLayer = window.dataLayer || []
    function gtag() {
        dataLayer.push(arguments)
    }

    // Default ad_storage and to 'denied'.
    gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
    })
}
