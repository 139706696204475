import axios from 'axios'

const state = {
    authorized: false,
}

const onMessage = (event, commit) => {
    if (event.origin === process.env.VUE_APP_NODE_API_HOST.replace('/api', '')) {
        window.focus()
        window.removeEventListener('message', onMessage)
        const message = JSON.parse(event.data)

        if (message && message.hasOwnProperty('success')) {
            commit('SET_AUTHORIZED', message.success)
        }
    }
}

const actions = {
    async upsertToken({commit}) {
        try {
            return new Promise(async (resolve, reject) => {
                const response = await axios.post(`${process.env.VUE_APP_NODE_API_HOST}/oauth/oauthEncrypt`)
                const {content, iv} = response.data.data

                const popup = window.open(
                    `${process.env.VUE_APP_NODE_API_HOST}/collect/crite/o/auth?content=${content}&iv=${iv}`,
                    'criteoAuth',
                    'resizable,scrollbars,status'
                )

                window.addEventListener('message', event => onMessage(event, commit))

                var timer = setInterval(() => {
                    if (popup.closed) {
                        clearInterval(timer)
                        window.removeEventListener('message', onMessage)

                        setTimeout(() => {
                            if (state.authorized) {
                                commit('SET_AUTHORIZED', false)
                                return resolve(true)
                            } else {
                                return reject(false)
                            }
                        }, 250)
                    }
                }, 1000)
            })
        } catch (error) {
            throw error
        }
    },
}

const mutations = {
    SET_AUTHORIZED(state, authorized) {
        state.authorized = authorized
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
