import axios from 'axios'

const state = {
    account: null,
    accounts: [],
    webProperties: [],
    webProperty: null,
    customDefinitions: [],
    profiles: [],
    profile: null,
    goals: [],
    multiGoals: [],
    customGoals: [],
    analyticsGoals: [],
}

const getters = {
    accounts: state => state.accounts,
    account: state => state.account,
    webProperties: state => state.webProperties,
    webProperty: state => state.webProperty,
    customDefinitions: state => state.customDefinitions,
    profiles: state => state.profiles,
    profile: state => state.profile,
    goals: state => state.goals,
    multiGoals: state => state.multiGoals,
    customGoals: state => state.customGoals,
    analyticsGoals: state => state.analyticsGoals,
}

const actions = {
    init({commit}) {
        commit('SET_ACCOUNTS', [])
        commit('SET_ACCOUNT', null)
        commit('SET_WEB_PROPERTIES', [])
        commit('SET_WEB_PROPERTY', null)
        commit('SET_PROFILES', [])
        commit('SET_PROFILE', null)
        commit('SET_GOALS', [])
        commit('SET_CUSTOM_DEFINITIONS', [])
        commit('SET_MULTIGOALS', [])
        commit('SET_CUSTOM_GOALS', [])
        commit('SET_ANALYTICS_GOALS', [])
    },

    async getAccounts({commit}, tokenId) {
        const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/google/getAnalyticsAccounts`, {
            params: {tokenId},
        })

        const accounts = response.data.data

        commit('SET_ACCOUNTS', accounts)
        return accounts
    },

    async getWebProperties({commit}, options) {
        const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/google/getAnalyticsWebProperties`, {
            params: {tokenId: options.tokenId, accountId: options.accountId},
        })

        const webProperties = response.data.data

        commit('SET_WEB_PROPERTIES', webProperties)
        return webProperties
    },

    async getCustomDefinitions({commit}, options) {
        const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/google/getAnalyticsCustomDefinitions`, {
            params: {accountId: options.accountId, webPropertyId: options.webPropertyId, tokenId: options.tokenId},
        })

        const customDefinitions = response.data.data

        commit('SET_CUSTOM_DEFINITIONS', customDefinitions)
        return customDefinitions
    },

    async getProfiles({commit}, options) {
        const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/google/getAnalyticsProfiles`, {
            params: {tokenId: options.tokenId, accountId: options.accountId, webPropertyId: options.webPropertyId},
        })

        const profiles = response.data.data

        commit('SET_PROFILES', profiles)
        return profiles
    },

    async getGoals({commit}, options) {
        const requestData = {
            accountId: options.accountId,
            webPropertyId: options.webPropertyId,
            profileId: options.profileId,
        }

        const response = await gapi.client.analytics.management.goals.list(requestData)

        const goals = response.result.items.map(goal => {
            const goalFulfillment =
                goal.urlDestinationDetails ||
                goal.visitTimeOnSiteDetails ||
                goal.visitNumPagesDetails ||
                goal.eventDetails
            return {
                id: goal.id,
                name: goal.name,
                type: goal.type,
                active: goal.active ? 'Yes' : 'No',
                activeString: goal.active.toString(),
                value: goal.value,
                goalFulfillment,
            }
        })

        commit('SET_GOALS', goals)

        return goals
    },

    async getMultiGoals({commit}, options) {
        const {accountId, webPropertyId, profiles} = options
        const multiGoals = await Promise.all(
            profiles.map(async profile => {
                const requestData = {
                    accountId,
                    webPropertyId,
                    profileId: profile.id,
                }

                const response = await gapi.client.analytics.management.goals.list(requestData)

                return {
                    id: profile.id,
                    label: profile.name,
                    children: response.result.items.map(goal => {
                        const goalFulfillment =
                            goal.urlDestinationDetails ||
                            goal.visitTimeOnSiteDetails ||
                            goal.visitNumPagesDetails ||
                            goal.eventDetails
                        return {
                            id: `${profile.id}/${goal.id}`,
                            label: goal.name,
                            name: goal.name,
                            type: goal.type,
                            active: goal.active ? 'Yes' : 'No',
                            activeString: goal.active.toString(),
                            value: goal.value,
                            goalFulfillment,
                        }
                    }),
                }
            })
        )

        commit('SET_MULTIGOALS', multiGoals)

        return multiGoals
    },

    async getCustomGoals({commit}, pipelineId) {
        const {
            data: {data},
        } = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/process/customGoals/resource/${pipelineId}`)
        const customGoals = data ? data.goalsQuery : []

        commit('SET_CUSTOM_GOALS', customGoals)
        return customGoals
    },

    async getAnalyticsGoals({commit}, pipelineId) {
        const {
            data: {data},
        } = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/process/analyticsGoals/${pipelineId}/list`)

        commit('SET_ANALYTICS_GOALS', data)
        return data
    },
}

const mutations = {
    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts
    },

    SET_ACCOUNT(state, account) {
        state.account = account
    },

    SET_WEB_PROPERTIES(state, webProperties) {
        state.webProperties = webProperties
    },

    SET_WEB_PROPERTY(state, webProperty) {
        state.webProperty = webProperty
    },

    SET_PROFILES(state, profiles) {
        state.profiles = profiles
    },

    SET_PROFILE(state, profile) {
        state.profile = profile
    },

    SET_GOALS(state, goals) {
        state.goals = goals
    },

    SET_CUSTOM_DEFINITIONS(state, customDefinitions) {
        state.customDefinitions = customDefinitions
    },

    SET_MULTIGOALS(state, multiGoals) {
        state.multiGoals = multiGoals
    },

    SET_CUSTOM_GOALS(state, customGoals) {
        state.customGoals = customGoals
    },

    SET_ANALYTICS_GOALS(state, analyticsGoals) {
        state.analyticsGoals = analyticsGoals
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
