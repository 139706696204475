import axios from 'axios'

const actions = {
    async upsertToken({commit}, options) {
        const response = await axios.post(`${process.env.VUE_APP_NODE_API_HOST}/collect/awin/credentials`, options.data)
        return response
    },
}

export default {
    namespaced: true,
    actions,
}
