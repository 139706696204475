import axios from 'axios'
import Vue from 'vue'

const state = {
    auth: null,
    accounts: [],
    account: null,
}

const getters = {
    auth: state => state.auth,
    accounts: state => state.accounts,
}

const actions = {
    init({commit}) {
        commit('SET_ACCOUNTS', [])
        commit('SET_ACCOUNT', null)
    },

    async authorize() {
        return new Promise((resolve, reject) => {
            Vue.FB.login(
                async response => {
                    const apiResponse = await axios.post(
                        `${process.env.VUE_APP_NODE_API_HOST}/collect/facebook/exchangeToken`,
                        {
                            fbExchangeToken: response.authResponse.accessToken,
                        }
                    )

                    resolve(apiResponse.data.data)
                },
                {
                    scope: 'ads_management',
                    return_scopes: true,
                }
            )
        })
    },
    async setFacebookAccounts({commit}, tokenId) {
        const apiResponse = await axios.post(`${process.env.VUE_APP_NODE_API_HOST}/collect/facebook/accounts`, {
            tokenId,
        })
        commit('SET_ACCOUNTS', apiResponse.data.data)
    },

    async upsertToken({commit}, options) {
        return new Promise((resolve, reject) => {
            Vue.FB.login(
                async response => {
                    const data = {
                        fbExchangeToken: response.authResponse.accessToken,
                        projectId: options.projectId,
                        scope: response.authResponse.grantedScopes,
                        userId: response.authResponse.userID,
                    }

                    try {
                        const apiResponse = await axios.post(
                            `${process.env.VUE_APP_NODE_API_HOST}/collect/facebook/credentials`,
                            data
                        )

                        resolve(apiResponse.data.data)
                    } catch (e) {
                        reject(e)
                    }
                },
                {
                    scope: options.scope,
                    return_scopes: true,
                }
            )
        })
    },

    async fetchPages({commit}, options) {
        return new Promise((resolve, reject) => {
            Vue.FB.api('/me/accounts', {access_token: options.accessToken, limit: 250}, response => {
                resolve(response)
            })
        })
    },

    async fetchForms({commit}, options) {
        return new Promise((resolve, reject) => {
            Vue.FB.api(
                `/${options.pageId}/leadgen_forms`,
                {access_token: options.accessToken, limit: 250},
                response => {
                    resolve(response)
                }
            )
        })
    },

    async subscribeApp({commit}, options) {
        return new Promise((resolve, reject) => {
            Vue.FB.api(
                `/${options.pageId}/subscribed_apps`,
                'post',
                {access_token: options.accessToken, subscribed_fields: ['leadgen'], limit: 250},
                response => {
                    resolve(response)
                }
            )
        })
    },
}

const mutations = {
    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts
    },

    SET_ACCOUNT(state, account) {
        state.account = account
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
