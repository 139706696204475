const state = {
    unauthorizedRetried: false,
}

const getters = {
    unauthorizedRetried: state => state.unauthorizedRetried,
}

const mutations = {
    SET_UNAUTHORIZED_RETRIED(state, unauthorizedRetried) {
        state.unauthorizedRetried = unauthorizedRetried
    },
    SET_USER_IN_STORAGE(state, user) {
        localStorage.setItem('userId', user.id)
        localStorage.setItem('internal', user.email.includes('witbee.com'))
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}
