import axios from 'axios'

const state = {
    customers: [],
    customer: null,
    fetchGoogleAds: false,
    conversionTrackers: [],
}

const getters = {
    customers: state => state.customers,
    customer: state => state.customer,
    conversionTrackers: state => state.conversionTrackers,
}

const actions = {
    init({commit}) {
        commit('SET_CUSTOMERS', [])
        commit('SET_CUSTOMER', null)
        commit('SET_CONVERSION_TRACKERS', [])
        commit('INIT_FETCH_ADS')
    },

    async getGoogleAdsCustomers({commit}, tokenId) {
        const response = await axios.post(`${process.env.VUE_APP_NODE_API_HOST}/collect/googleAds/getCustomers`, {
            tokenId,
        })

        commit('SET_CUSTOMERS', response.data.data)

        return response.data
    },

    async fetchConversionTrackers({commit}, options) {
        const {tokenId, customerId, rootCustomerId} = options
        const response = await axios.post(
            `${process.env.VUE_APP_NODE_API_HOST}/collect/googleAds/getConversionTrackers`,
            {
                tokenId,
                customerId,
                rootCustomerId,
            }
        )

        commit('SET_CONVERSION_TRACKERS', response.data.data)
        return response.data.data
    },
}

const mutations = {
    SET_CUSTOMERS(state, customers) {
        state.customers = customers
    },
    SET_CUSTOMER(state, customer) {
        state.customer = customer
    },
    INIT_FETCH_ADS(state) {
        state.fetchGoogleAds = false
    },
    SET_FETCH_ADS(state) {
        state.fetchGoogleAds = !state.fetchGoogleAds
    },
    SET_CONVERSION_TRACKERS(state, conversionTrackers) {
        state.conversionTrackers = conversionTrackers
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
