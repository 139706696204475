import axios from 'axios'

const state = {
    all: [],
}

const getters = {
    all: state => state.all,
}

const actions = {
    async fetchAll({commit}, {projectId, cancelToken}) {
        try {
            const response = await axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/process?projectId=${projectId}&page=1&limit=250&ascending=0&byColumn=createdAt`,
                {
                    cancelToken,
                }
            )

            commit('SET_ALL', response.data.data)
        } catch (err) {
            if (axios.isCancel(err)) {
                return
            } else {
                throw err
            }
        }
    },

    async getOne({commit}, processId) {
        const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/process/${processId}`)

        return response.data.data
    },
}

const mutations = {
    SET_ALL(state, all) {
        state.all = all
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
