import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import allegro from './modules/allegro'
import analytics from './modules/analytics'
import aside from './modules/aside'
import auth from './modules/auth'
import awin from './modules/awin'
import baselinker from './modules/baselinker'
import bigquery from './modules/bigquery'
import billing from './modules/billing'
import bing from './modules/bing'
import ceneo from './modules/ceneo'
import collect from './modules/collect'
import convertiser from './modules/convertiser'
import criteo from './modules/criteo'
import crm from './modules/crm'
import domodi from './modules/domodi'
import facebook from './modules/facebook'
import firebase from './modules/firebase'
import form from './modules/form'
import google from './modules/google'
import googleads from './modules/googleads'
import hubspot from './modules/hubspot'
import idosell from './modules/idosell'
import woocommerce from './modules/woocommerce'
import loading from './modules/loading'
import magento from './modules/magento'
import notification from './modules/notification'
import optimise from './modules/optimise'
import permission from './modules/permission'
import pipeline from './modules/pipeline'
import prestashop from './modules/prestashop'
import process from './modules/process'
import project from './modules/project'
import report from './modules/report'
import rtb from './modules/rtb'
import shoper from './modules/shoper'
import shopify from './modules/shopify'
import sidebar from './modules/sidebar'
import tiktok from './modules/tiktok'
import token from './modules/token'
import tradedoubler from './modules/tradedoubler'
import tutorial from './modules/tutorial'
import workflow from './modules/workflow'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        allegro,
        analytics,
        aside,
        auth,
        awin,
        convertiser,
        baselinker,
        bigquery,
        billing,
        collect,
        crm,
        facebook,
        firebase,
        form,
        google,
        googleads,
        hubspot,
        loading,
        magento,
        prestashop,
        idosell,
        woocommerce,
        notification,
        optimise,
        permission,
        pipeline,
        process,
        project,
        report,
        rtb,
        domodi,
        ceneo,
        shoper,
        shopify,
        sidebar,
        token,
        tiktok,
        bing,
        tradedoubler,
        tutorial,
        workflow,
        notification,
        criteo,
    },
    plugins: [
        createPersistedState({
            paths: ['analytics', 'aside', 'billing', 'form', 'onboarding', 'project', 'sidebar', 'tutorial'],
        }),
    ],
})
