import axios from 'axios'

const actions = {
    async upsertToken({commit}, options) {
        try {
            const response = await axios.post(
                `${process.env.VUE_APP_NODE_API_HOST}/collect/convertiser/credentials`,
                options.data
            )
            return response
        } catch (e) {
            throw e
        }
    },
}

export default {
    namespaced: true,
    actions,
}
