import axios from 'axios'

const state = {
    tokens: [],
}

const getters = {
    tokens: (state) => state.tokens,
    awinTokens: (state) => state.tokens.filter((el) => el.type === 'Awin'),
    convertiserTokens: (state) => state.tokens.filter((el) => el.type === 'Convertiser'),
    baselinkerTokens: (state) => state.tokens.filter((el) => el.type === 'Baselinker'),
    criteoTokens: (state) => state.tokens.filter((el) => el.type === 'Criteo'),
    facebookTokens: (state) => state.tokens.filter((el) => el.type === 'Facebook'),
    googleTokens: (state) => state.tokens.filter((el) => el.type === 'Google'),
    hubspotTokens: (state) => state.tokens.filter((el) => el.type === 'HubSpot'),
    magentoTokens: (state) => state.tokens.filter((el) => el.type === 'Magento'),
    prestashopTokens: (state) => state.tokens.filter((el) => el.type === 'PrestaShop'),
    idoSellTokens: (state) => state.tokens.filter((el) => el.type === 'IdoSell'),
    woocommerceTokens: (state) => state.tokens.filter((el) => el.type === 'WooCommerce'),
    optimiseTokens: (state) => state.tokens.filter((el) => el.type === 'Optimise'),
    rtbTokens: (state) => state.tokens.filter((el) => el.type === 'RTBHouse'),
    domodiTokens: (state) => state.tokens.filter((el) => el.type === 'Domodi'),
    ceneoTokens: (state) => state.tokens.filter((el) => el.type === 'Ceneo'),
    shoperTokens: (state) => state.tokens.filter((el) => el.type === 'Shoper'),
    shopifyTokens: (state) => state.tokens.filter((el) => el.type === 'Shopify'),
    tradedoublerTokens: (state) => state.tokens.filter((el) => el.type === 'Tradedoubler'),
    tiktokTokens: (state) => state.tokens.filter((el) => el.type === 'TikTok'),
    bingAdsTokens: (state) => state.tokens.filter((el) => el.type === 'Bing'),
    allegroTokens: (state) => state.tokens.filter((el) => el.type === 'Allegro'),
}

const actions = {
    async fetchTokens({commit, rootGetters}, projectId) {
        try {
            const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/oauth`, {
                params: {
                    projectId: projectId ? projectId : rootGetters['project/active'].id,
                },
            })

            commit('SET_TOKENS', response.data.data)

            return response.data.data
        } catch (error) {
            throw error
        }
    },
}

const mutations = {
    SET_TOKENS(state, tokens) {
        state.tokens = tokens
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
