import axios from 'axios'

const state = {
    accounts: [],
    active: null,
}

const getters = {
    accounts: (state) => state.accounts,
    active: (state) => state.active,
    trialAccount: (state) => {
        if (state.active) {
            if (
                !state.active.active &&
                state.active.trialEnd &&
                new Date(state.active.trialEnd).getTime() > new Date().getTime()
            ) {
                return state.active
            }
        } else {
            if (
                state.accounts[0] &&
                !state.accounts[0].active &&
                state.accounts[0].trialEnd &&
                new Date(state.accounts[0].trialEnd).getTime() > new Date().getTime()
            ) {
                return state.accounts[0]
            }
        }

        return null
    },
}

const actions = {
    async getAccounts({commit, state}) {
        try {
            const response = await axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/billingAccount?limit=250&ascending=asc&page=1`
            )

            const accounts = await Promise.all(
                response.data.data.map(async (account) => {
                    const {
                        data: {data},
                    } = await axios.get(
                        `${process.env.VUE_APP_NODE_API_HOST}/billingAccount/${account.id}/paymentMethods`
                    )

                    return {
                        ...account,
                        paymentMethods: data,
                    }
                })
            )

            if (state.active) {
                const account = accounts.find((el) => el.id === state.active.id)

                if (account) {
                    commit('SET_ACTIVE', account)
                }
            }

            commit('SET_ACCOUNTS', accounts)

            return accounts
        } catch {
            return []
        }
    },

    async setAccount({commit, state}, id) {
        try {
            const {
                data: {data: account},
            } = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/billingAccount/${id}`)

            let paymentMethods = []
            try {
                const {
                    data: {data},
                } = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/billingAccount/${account.id}/paymentMethods`)
                paymentMethods = data
            } catch {}

            const newAccount = {...account, paymentMethods}

            const accounts = [...state.accounts]
            const indexFound = accounts.findIndex((el) => el.id === account.id)

            if (indexFound > -1) {
                accounts[indexFound] = newAccount
            } else {
                accounts.push(newAccount)
            }

            commit('SET_ACTIVE', newAccount)
            commit('SET_ACCOUNTS', accounts)
        } catch {
            commit('SET_ACTIVE', null)
        }
    },
}

const mutations = {
    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts
    },

    SET_ACTIVE(state, active) {
        state.active = active
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
