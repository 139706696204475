import axios from "axios";

const state = {
  active: null,
  list: []
};

const getters = {
  active: state => state.active,
  list: state => state.list
};

const actions = {
  async createPermission({ commit }, data) {
    const response = await axios.post(
      `${process.env.VUE_APP_NODE_API_HOST}/permissions`,
      { permissionData: data.permissionData, projectId: data.projectId }
    );

    commit("CREATE_PERMISSION", response.data.permission)

    return response.data.permission
  },

  async getPermissions({ commit }) {
    const response = await axios.get(
      process.env.VUE_APP_NODE_API_HOST + "/permissions"
    );
    commit("SET_PERMISSIONS", response.data.data);

    return response.data.data;
  },

};

const mutations = {
  SET_PERMISSIONS(state, list) {
    state.list = list;
  },
  SET_PERMISSION(state, active) {
    state.active = active;
  },
  CREATE_PERMISSION(state, permission) {
    state.list.push(permission);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
