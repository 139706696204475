
const StoryblokClient = require('storyblok-js-client')

export default {
    install(Vue, options) {
        const client = new StoryblokClient({
            accessToken: options.key
        })

        Vue.prototype.$storyblok = client
    }
}