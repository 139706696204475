// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import WitSelect from '@/components/Inputs/WitSelect'
import ProjectRouterLink from '@/components/ProjectRouterLink'
import Logging from '@/plugins/logging'
import ProjectRouter from '@/plugins/projectRouter'
import RandomId from '@/plugins/randomId'
import Storyblok from '@/plugins/storyblok'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import dotenv from 'dotenv'
import miniToastr from 'mini-toastr'
import VScrollLock from 'v-scroll-lock'
import VeeValidate from 'vee-validate'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueGtm from 'vue-gtm'
import VueNotifications from 'vue-notifications'
import {uuid} from 'vue-uuid'
import App from './App'
import {initFbSdk} from './libs/facebookSdk'
import {initCookiebotScript} from './libs/cookieBot'
import './polyfill'
import router from './router/index'
import store from './store'

initFbSdk(Vue, process.env.VUE_APP_FACEBOOK_CLIENT_ID)
initCookiebotScript()

const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn',
}

miniToastr.init({
    types: toastTypes,
})

function toast({title, message, type, timeout, cb}) {
    return miniToastr[type](message, title, timeout, cb)
}

Vue.use(VueNotifications, {
    success: toast,
    error: toast,
    info: toast,
    warn: toast,
})

Vue.use(VeeValidate, {fieldsBagName: 'veeFields'})
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(VScrollLock)
Vue.use(Logging)
Vue.use(RandomId)
Vue.use(Storyblok, {key: process.env.VUE_APP_STORYBLOK_API_KEY})
Vue.component('wit-select', WitSelect)
Vue.component('project-router-link', ProjectRouterLink)

Vue.router = router
Vue.use(ProjectRouter, {router, store})

axios.interceptors.response.use(
    response => {
        store.commit('auth/SET_UNAUTHORIZED_RETRIED', false)
        return response
    },
    error => {
        return new Promise(async (resolve, reject) => {
            if (error.config && error.config.url === `${process.env.VUE_APP_NODE_API_HOST}/auth/refresh`) {
                store.commit('auth/SET_UNAUTHORIZED_RETRIED', false)
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('accessToken')
                router.push('/auth/login')
                store.commit('loading/PROCESSED')
                return reject(error)
            }
            if (error.response && error.response.status === 401) {
                if (localStorage.refreshToken && !store.state.auth.unauthorizedRetried) {
                    const response = await axios.post(process.env.VUE_APP_NODE_API_HOST + '/auth/refresh', {
                        refreshToken: localStorage.refreshToken,
                    })
                    const {refreshToken, accessToken} = response.data
                    localStorage.refreshToken = refreshToken
                    localStorage.accessToken = accessToken
                    const {Authorization, ...headers} = error.config.headers
                    const newHeaders = Object.assign({}, headers, {Authorization: `Bearer ${accessToken}`})
                    const retry = Object.assign({}, error.config, {headers: newHeaders})
                    store.commit('auth/SET_UNAUTHORIZED_RETRIED', true)
                    return resolve(axios.request(retry))
                } else {
                    if (window.location.hash !== '/#/auth/login') {
                        router.push('/auth/login')
                    }
                    return reject(error)
                }
            }
            return reject(error)
        })
    }
)

Vue.use(require('@websanova/vue-auth'), {
    auth: {
        request: function(req, token) {
            this.options.http._setHeaders.call(this, req, {
                Authorization: 'Bearer ' + token,
                'x-witcloud-project-id': store.state.project.active ? store.state.project.active.id : undefined,
            })
        },

        response: function(res) {
            if (res.data && res.data.data && res.data.data.accessToken) {
                const {accessToken, refreshToken} = res.data.data
                localStorage.refreshToken = refreshToken
                localStorage.accessToken = accessToken
                return accessToken
            }
        },
    },
    tokenDefaultName: 'accessToken',
    loginData: {
        url: process.env.VUE_APP_NODE_API_HOST + '/auth/login',
        fetchUser: true,
    },
    logoutData: {
        url: process.env.VUE_APP_NODE_API_HOST + '/auth/logout',
        method: 'POST',
        redirect: '/auth/login',
        makeRequest: true,
    },
    fetchData: {
        url: process.env.VUE_APP_NODE_API_HOST + '/auth/user',
        method: 'GET',
        enabled: true,
        success() {
            HelpCrunch('init', 'witcloud', {
                applicationId: 1,
                applicationSecret:
                    'jlYCLsbPb5aYaC7nNu56nCM1sL5SWL4l0lK8MhReA/j0PyaKXQwLg7tex2epKhZr4n+fLrbvttwsTJ/JDAnH8Q==',
                user: {
                    name: Vue.auth.user().email,
                    email: Vue.auth.user().email,
                    user_id: Vue.auth.user().id,
                    custom_data: {
                        registerDate: Vue.auth.user().createdAt,
                    },
                },
            })

            HelpCrunch('showChatWidget')
        },
    },
    refreshData: {enabled: false},
    registerData: {
        url: process.env.VUE_APP_NODE_API_HOST + '/auth/register',
        method: 'POST',
        redirect: '/auth/registered',
    },
    authRedirect: {
        path: '/auth/login',
    },
    notFoundRedirect: {path: '/404'},
    http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
})

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    compatibility: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
})

router.beforeEach(async (to, from, next) => {
    if (to.path.includes('create')) {
        const formSessionIdMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
        if (!formSessionIdMap) {
            const formSessionIdMap = {}
            localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionIdMap))
        }

        const formSessionId = formSessionIdMap[to.path]
        if (!formSessionId || (formSessionId && formSessionId.ttl < new Date().getTime())) {
            const formSession = {
                id: uuid.v4(),
                ttl: new Date().getTime() + 30 * 60000,
            }

            formSessionIdMap[to.path] = formSession
        } else {
            const formSession = {
                id: formSessionId.id,
                ttl: new Date().getTime() + 30 * 60000,
            }

            formSessionIdMap[to.path] = formSession
        }

        localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionIdMap))
    }

    if (to.meta && to.meta.hasOwnProperty('auth') && !to.meta.auth) {
        return next()
    }

    const user = Vue.auth.user()
    if (user && Object.keys(user).length) {
        store.commit('auth/SET_USER_IN_STORAGE', user)
    }

    if (!store.state.project.projects.length) {
        await store.dispatch('project/getProjects')
    }

    let project = null
    const {projectId} = to.params
    const {projects} = store.state.project

    if (projectId) {
        project = projects.find(el => el.id === projectId)
    }

    if (project) {
        await store.dispatch('project/setProject', project)
        return next()
    } else {
        const previousProject = store.state.project.previousProjectId
            ? projects.find(el => el.id === store.state.project.previousProjectId)
            : null
        project = previousProject ? previousProject : projects[0]

        await store.dispatch('project/setProject', project)

        if (project && project.type !== 'SANDBOX' && !project.authorized) {
            if (to.path.includes('/p/') && to.path.includes('/iam/projects/create')) {
                return next()
            } else {
                return next(`/p/${project.id}/iam/projects/create?id=${project.id}`)
            }
        }
    }

    if (!projects.length && !to.path.includes('/iam/projects')) {
        return next(`/iam/projects/create`)
    }

    if (to.matched.length && to.meta.noProjectRequired) {
        return next()
    } else {
        if (to.matched.length) {
            return next({path: to.path.replace(projectId, project.id), query: {projectNotFound: projectId}})
        } else {
            const {resolved} = router.resolve(`p/${project.id}${to.path}`, '/')

            if (resolved.matched.length) {
                return next({path: resolved.path, query: {projectSet: true}})
            } else {
                return next({path: `/p/${project.id}/dashboard`, query: {routeNotFound: true}})
            }
        }
    }
})

Vue.mixin({
    beforeRouteLeave(to, from, next) {
        sessionStorage.setItem('referrer', from.path)
        if (!to.path.includes('/auth') && !from.path.includes('/auth')) {
            localStorage.setItem('lastDesiredPath', to.path)
        }

        next()
    },
})

Vue.config.errorHandler = (err, vm, info) => {
    if (['development', 'local'].includes(process.env.NODE_ENV)) {
        console.error(err)
    }
    vm.$errorHandler.report(err, info)
}

dotenv.config()

new Vue({
    el: '#app',
    router,
    store,
    axios,
    template: '<App/>',
    components: {
        App,
    },
})
