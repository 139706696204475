import axios from 'axios'

const state = {
    datasets: [],
    dataset: null,
    tables: [],
    projects: [],
    project: null,
    locations: [],
    location: null,
}

const getters = {
    projects: state => state.projects,
    project: state => state.project,
    datasets: state => state.datasets,
    dataset: state => state.dataset,
    tables: state => state.tables,
    locations: state => state.locations,
    location: state => state.location,
}

const actions = {
    async init({commit}) {
        commit('SET_PROJECTS', [])
        commit('SET_PROJECT', null)
        commit('SET_DATASETS', [])
        commit('SET_DATASET', {})
        commit('SET_TABLES', [])
        commit('SET_LOCATIONS', [])
        commit('SET_LOCATION', null)
    },

    async createDataset({commit}, options) {
        const response = await axios.post(`${process.env.VUE_APP_NODE_API_HOST}/bigquery/datasets`, {
            projectId: options.projectId,
            friendlyName: options.friendlyName,
            authService: options.authServices.bigQuery,
        })

        return response
    },

    async getProjects({commit}, projectId) {
        try {
            const projectsResponse = await axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/google/projects?witcloudProjectId=${projectId}`
            )
            const projects = projectsResponse.data.data.filter(el => el !== process.env.VUE_APP_CLOUD_PROJECT)

            commit('SET_PROJECTS', projects)

            return projects
        } catch (exception) {
            throw exception
        }
    },

    async getDatasets({commit}, options) {
        const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/google/datasets`, {
            params: {witcloudProjectId: options.witcloudProjectId, googleCloudProjectId: options.googleCloudProjectId},
        })
        const datasets = response.data.data

        commit('SET_DATASETS', datasets)
        return datasets
    },

    async getTables({commit}, options) {
        const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/google/tables`, {
            params: {
                witcloudProjectId: options.witcloudProjectId,
                googleCloudProjectId: options.googleCloudProjectId,
                datasetId: options.datasetId,
            },
        })
        const tables = response.data.data

        commit('SET_TABLES', tables)
        return tables
    },

    async getLocations({commit}) {
        const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/google/locations`)

        const locations = response.data ? response.data.data : []

        commit('SET_LOCATIONS', locations)
        return locations
    },

    async testSourceMedium({commit}, options) {
        const response = await axios.post(`${process.env.VUE_APP_NODE_API_HOST}/collect/criteo/testSourceMedium`, {
            sourceMedium: options.sourceMedium,
            customRegex: options.customRegex,
            collectId: options.collectId,
        })
        const sessionTraffic = response.data.data
        const sessionCount = response.data.metadata.count
        return {sessionTraffic, sessionCount}
    },
}

const mutations = {
    SET_PROJECTS(state, projects) {
        state.projects = projects
    },

    SET_PROJECT(state, project) {
        state.project = project
    },

    SET_DATASETS(state, datasets) {
        state.datasets = datasets
    },

    SET_DATASET(state, dataset) {
        state.dataset = dataset
    },

    SET_TABLES(state, tables) {
        state.tables = tables
    },

    SET_LOCATIONS(state, locations) {
        state.locations = locations
    },

    SET_LOCATION(state, location) {
        state.location = location
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
