import axios from 'axios'

const state = {
    projects: [],
    active: null,
    previousProjectId: '',
}

const getters = {
    projects: state => state.projects,
    active: state => state.active,
    locked: state => !Boolean(state.active && (state.active.type === 'SANDBOX' || state.active.authorized)),
    previousProjectId: state => state.previousProjectId,
}

const actions = {
    async createProject({commit}, data) {
        const response = await axios.post(`${process.env.VUE_APP_NODE_API_HOST}/projects`, {
            projectData: data.projectData,
        })

        commit('CREATE_PROJECT', response.data.project)
        commit('SET_PROJECT', response.data.project)

        return response.data.project
    },

    async getProjects({commit}) {
        const response = await axios.get(process.env.VUE_APP_NODE_API_HOST + '/projects?page=1&limit=250&ascending=0')

        commit('SET_PROJECTS', response.data.data)

        return response.data.data
    },

    async setProject({commit, dispatch, rootGetters, state}, project) {
        const previousProjectId = state.active ? state.active.id : null
        commit('SET_PREVIOUS_PROJECT_ID', previousProjectId)
        commit('SET_PROJECT', project)

        if (project && project.id !== previousProjectId) {
            if (project.type !== 'SANDBOX') {
                if (project.billingAccountRef && project.billingAccountRef.id) {
                    await dispatch('billing/setAccount', project.billingAccountRef.id, {root: true})
                }
            } else {
                if (rootGetters['billing/trialAccount']) {
                    commit('billing/SET_ACTIVE', rootGetters['billing/trialAccount'], {root: true})
                } else {
                    commit('billing/SET_ACTIVE', null, {root: true})
                }
            }
        } else {
            const activeBillingAccount = rootGetters['billing/active']

            if (
                activeBillingAccount &&
                project &&
                project.billingAccountRef &&
                activeBillingAccount.id === project.billingAccountRef.id
            ) {
                commit('billing/SET_ACTIVE', activeBillingAccount, {root: true})
            } else {
                if (project && project.billingAccountRef && project.billingAccountRef.id) {
                    await dispatch('billing/setAccount', project.billingAccountRef.id, {root: true})
                } else {
                    commit('billing/SET_ACTIVE', null, {root: true})
                }
            }
        }
    },
}

const mutations = {
    SET_PROJECTS(state, projects) {
        state.projects = projects
    },
    SET_PROJECT(state, project) {
        state.active = project
        localStorage.activeProjectId = project ? project.id : undefined
    },
    CREATE_PROJECT(state, project) {
        state.projects.push(project)
    },
    SET_PREVIOUS_PROJECT_ID(state, id) {
        state.previousProjectId = id
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
