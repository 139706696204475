import axios from "axios";

const state = {
    validDatasets: [],
    fields: [],
    isValidDataset: null,
};

const getters = {
    validDatasets: state => state.validDatasets,
    isValidDataset: state => state.isValidDataset,
    fields: state => state.fields,
};

const actions = {
    init({ commit }) {
        commit('SET_ISVALIDDATASET', null)
        commit('SET_VALID_DATASETS', [])
        commit('SET_FIELDS', [])
    },

    async getFields({ commit }) {
        try {
            const response = await axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/firebase/fieldsList`,
                {
                    params: {
                        tableType: 'events'
                    }
                }
            )

            commit("SET_FIELDS", response.data.data.fields);

            return response.data.data.fields
        } catch (exception) {
            throw exception
        }
    },

    async getValidDatasets({ commit }, options) {
        const response = await axios.get(
            `${process.env.VUE_APP_NODE_API_HOST}/firebase/validTablesList`,
            {
                params: {
                    projectId: options.projectId
                }
            }
        )
        const datasets = response.data ? response.data.data.validDatasets : []

        commit("SET_VALID_DATASETS", datasets)
        return datasets
    },

    async verifyGoals({ commit }, options) {
        const response = await axios.post(
            `${process.env.VUE_APP_NODE_API_HOST}/firebase/validateQuery`,
            options
        )
        return response.data.data.isValid
    }
}


const mutations = {
    SET_ISVALIDDATASET(state, isValidDataset) {
        state.isValidDataset = isValidDataset
    },

    SET_VALID_DATASETS(state, datasets) {
        state.validDatasets = datasets
    },

    SET_FIELDS(state, fields) {
        state.fields = fields
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
