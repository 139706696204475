import axios from 'axios'

const state = {
    dataProviders: [],
    queryJobs: [],
}

const getters = {
    dataProviders: state => state.dataProviders,
    queryJobs: state => state.queryJobs,
    all: state => [...state.dataProviders, ...state.queryJobs],
}

const actions = {
    fetchAll({dispatch}, options) {
        const actions = [dispatch('fetchDataProviders', options), dispatch('fetchQueryJobs', options)]
        return Promise.all(actions)
    },

    async fetchDataProviders({commit}, {projectId, cancelToken}) {
        try {
            const response = await axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/reports/dataProviders?projectId=${projectId}&page=1&limit=250&ascending=0`,
                {
                    cancelToken,
                }
            )

            commit('SET_DATA_PROVIDERS', response.data.data)
        } catch (err) {
            if (axios.isCancel(err)) {
                return
            } else {
                throw err
            }
        }
    },

    async fetchQueryJobs({commit}, {projectId, cancelToken}) {
        try {
            const response = await axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/report/queryJob?projectId=${projectId}&page=1&limit=250&ascending=0`,
                {
                    cancelToken,
                }
            )

            commit('SET_QUERY_JOBS', response.data.data)
        } catch (err) {
            if (axios.isCancel(err)) {
                return
            } else {
                throw err
            }
        }
    },
}

const mutations = {
    SET_DATA_PROVIDERS(state, dataProviders) {
        state.dataProviders = dataProviders
    },

    SET_QUERY_JOBS(state, queryJobs) {
        state.queryJobs = queryJobs
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
