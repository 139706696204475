const state = {
    data: [],
    showReset: false,
}

const getters = {
    data: state => state.data,
    showReset: state => state.showReset,
}

const actions = {
    find({ commit, state }, formId) {
        const formFound = state.data.find(el => el.formId === formId)

        if (formFound) {
            return formFound
        } else {
            commit('UPSERT', {
                formId,
                data: {},
            })

            return state.data.find(el => el.formId === formId)
        }
    },
}

const mutations = {
    UPSERT(state, form) {
        const formFound = state.data.find(el => el.formId === form.formId)

        if (formFound) {
            formFound.data = form.data
            formFound.tabIndex = form.tabIndex
        } else {
            state.data.push({ formId: form.formId, data: form.data, tabIndex: form.tabIndex })
        }
    },

    CLEAR(state, formId) {
        const formFound = state.data.find(el => el.formId === formId)

        if (formFound) {
            formFound.data = {}
            formFound.tabIndex = 0
        }
    },

    SET_SHOWRESET(state, value) {
        state.showReset = value
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
