export default {
    install(Vue, {router, store}) {
        Vue.prototype.$projectRouter = {
            push: (location, onComplete, onAbort) => {
                let projectLocation

                if (typeof location === 'object' && location !== null && location.path) {
                    projectLocation = {
                        ...location,
                        path: `/p/${store.state.project.active.id}${location.path.substr(0, 1) === '/' ? '' : '/'}${
                            location.path
                        }`,
                    }
                } else {
                    projectLocation = `/p/${store.state.project.active.id}${
                        location.substr(0, 1) === '/' ? '' : '/'
                    }${location}`
                }

                return router.push(projectLocation, onComplete, onAbort)
            },
        }
    },
}
