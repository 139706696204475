import axios from 'axios'

const state = {
    auth: null,
    authServices: {},
}

const getters = {
    auth: state => state.auth,
    authServices: state => state.authServices,
}

const actions = {
    init({commit}) {
        const auth2 = gapi.auth2.init({
            client_id: process.env.VUE_APP_CLIENT_ID,
        })

        commit('SET_AUTH', auth2)
        commit('CLEAR_AUTH_SERVICES')
    },

    async authorizeGoogleApi({commit}, options) {
        try {
            const {code} = await options.authorization.grantOfflineAccess({scope: options.scopes})
            const response = await axios.post(`${process.env.VUE_APP_NODE_API_HOST}/google/authorize`, {
                code: code,
                tool: options.tool,
            })

            const service = {
                tool: options.tool,
                tokens: response.data.tokens,
            }

            commit('SET_AUTH_SERVICE', service)

            return service
        } catch (error) {
            throw error
        }
    },

    async upsertToken({commit}, options) {
        try {
            const auth2 = gapi.auth2.init({
                client_id: process.env.VUE_APP_CLIENT_ID,
            })

            const {code} = await auth2.grantOfflineAccess({scope: options.scope.join(' ')})

            const data = {
                code,
                projectId: options.projectId,
            }

            const response = await axios.post(`${process.env.VUE_APP_NODE_API_HOST}/google/credentials`, data)

            return response
        } catch (error) {
            throw error
        }
    },
}

const mutations = {
    SET_AUTH(state, auth) {
        state.auth = auth
    },
    SET_AUTH_SERVICE(state, service) {
        state.authServices[service.tool] = service.tokens
    },
    CLEAR_AUTH_SERVICES() {
        state.authServices = {}
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
