import axios from 'axios'

const state = {
    pipelines: [],
    pipeline: null,
}

const getters = {
    pipelines: state => state.pipelines,
    currPipeline: state => state.pipelines[state.pipelines.length - 1],
}

const actions = {
    async getPipelines({commit}, projectId) {
        const response = await axios.get(
            `${process.env.VUE_APP_NODE_API_HOST}/collect?projectId=${projectId}&page=1&limit=250&ascending=0`
        )

        commit('SET_PIPELINES', response.data.data)
    },

    async getOne({commit}, collectId) {
        const response = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/collect/${collectId}`)

        return response.data.data
    },
}

const mutations = {
    SET_PIPELINES(state, pipelines) {
        state.pipelines = pipelines
    },
    CREATE_PIPELINE(state, pipeline) {
        state.pipelines.push(pipeline)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
