export const initFbSdk = (Vue, facebookClientId) => {
    window.fbAsyncInit = () => {
        FB.init({
            status: false,
            cookie: true,
            appId: facebookClientId,
            xfbml: true,
            version: 'v6.0',
        })
        Vue.FB = FB
    }
    ;(function(d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
            return
        }
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
}
