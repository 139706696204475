const state = {
    processing: false,
    text: '',
    timeout: null,
}

const getters = {
    state: state => state,
}

const actions = {}

const mutations = {
    PROCESSING(state, text) {
        if (state.timeout) {
            clearTimeout(state.timeout)
            state.timeout = null
        }
        state.processing = true
        state.text = text
        state.timeout = setTimeout(() => (state.processing = false), 30000)
    },
    PROCESSED(state) {
        state.processing = false
        state.text = ''
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
