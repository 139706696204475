const state = {
    docs: [],
    docTree: null,
    slug: '',
    steps: [],
    forms: [],
    previousStepIndex: 0,
    stepIndex: 0,
    show: false,
    codelabs: [],
    tutorials: [],
    quickHelp: null,
    showQuickHelp: false,
    tutorialStep: '',
    data: [],
    addPadding: false,
    startSlug: '',
}

const getters = {
    docs: state => state.docs,
    docTree: state => state.docTree,
    slug: state => state.slug,
    steps: state => state.steps,
    forms: state => state.forms,
    previousStepIndex: state => state.previousStepIndex,
    stepIndex: state => state.stepIndex,
    show: state => state.show,
    codelabs: state => state.codelabs,
    tutorials: state => state.tutorials,
    quickHelp: state => state.quickHelp,
    showQuickHelp: state => state.showQuickHelp,
    tutorialStep: state => state.tutorialStep,
    addPadding: state => state.addPadding,
    pageLocked: state => state.show && state.steps.length > 0 && state.stepIndex >= 0,
    startSlug: state => state.startSlug,
}

const actions = {
    find({commit, state}, slug) {
        const tutorialFound = state.data.find(el => el.slug === slug)

        if (tutorialFound) {
            return tutorialFound
        } else {
            commit('UPSERT', {
                slug,
                data: {},
                forms: [],
            })

            return state.data.find(el => el.slug === slug)
        }
    },
}

const mutations = {
    SET_DOCS(state, docs) {
        state.docs = docs
    },
    SET_DOCTREE(state, docTree) {
        state.docTree = docTree
    },
    SET_SLUG(state, slug) {
        state.slug = slug
    },
    SET_STEPS(state, steps) {
        state.steps = steps
    },
    SET_FORMS(state, forms) {
        state.forms = forms
    },
    SET_PREVIOUSSTEPINDEX(state, previousStepIndex) {
        state.previousStepIndex = previousStepIndex
    },
    SET_STEPINDEX(state, stepIndex) {
        state.stepIndex = stepIndex
    },
    SET_SHOW(state, show) {
        state.show = show
    },
    SET_CODELABS(state, codelabs) {
        state.codelabs = codelabs
    },
    SET_TUTORIALS(state, tutorials) {
        state.tutorials = tutorials
    },
    SET_QUICKHELP(state, quickHelp) {
        state.quickHelp = quickHelp
    },
    SET_SHOWQUICKHELP(state, showQuickHelp) {
        state.showQuickHelp = showQuickHelp
    },
    SET_TUTORIALSTEP(state, tutorialStep) {
        state.tutorialStep = tutorialStep
    },
    SET_ADDPADDING(state, addPadding) {
        state.addPadding = addPadding
    },
    SET_STARTSLUG(state, startSlug) {
        state.startSlug = startSlug
    },
    UPSERT(state, tutorial) {
        const tutorialFound = state.data.find(el => el.slug === tutorial.slug)

        if (tutorialFound) {
            tutorialFound.data = tutorial.data
            tutorialFound.forms = tutorial.forms
        } else {
            state.data.push({slug: tutorial.slug, data: tutorial.data, forms: tutorial.forms})
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
