<template>
    <router-link :to="link">
        <slot />
    </router-link>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        to: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),

        link() {
            if (this.activeProject) {
                return `/p/${this.activeProject.id}${this.to.substr(0, 1) === '/' ? '' : '/'}${this.to}`
            } else return ''
        },
    },
}
</script>

<style></style>
