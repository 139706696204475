const state = {
    width: '200px',
    widthCollapsed: '50px',
    resizing: false,
    collapsed: false,
}

const getters = {
    width: state => state.width,
    widthCollapsed: state => state.widthCollapsed,
    resizing: state => state.resizing,
    collapsed: state => state.collapsed,
}

const mutations = {
    SET_WIDTH(state, width) {
        state.width = width
    },
    SET_WIDTH_COLLAPSED(state, widthCollapsed) {
        state.widthCollapsed = widthCollapsed
    },
    SET_RESIZING(state, resizing) {
        state.resizing = resizing
    },
    SET_COLLAPSED(state, collapsed) {
        state.collapsed = collapsed
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}
