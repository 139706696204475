const state = {
    all: [],
}

const getters = {
    all: state => state.all,
}

const mutations = {
    SET_ALL(state, all) {
        state.all = all
    },
}

const actions = {
    async fetchAll({commit}, storyblok) {
        const {
            data: {stories},
        } = await storyblok.get(`cdn/stories`, {
            starts_with: 'notifications',
            is_start_page: false,
            per_page: 100,
            version: ['development', 'local'].includes(process.env.NODE_ENV) ? 'draft' : 'published',
        })

        commit('SET_ALL', stories)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
