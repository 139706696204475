import axios from 'axios'

const state = {
    crmMetrics: [],
}

const getters = {
    crmMetrics: state => state.crmMetrics,
    crmDimensions: state => state.crmDimensions,
}

const actions = {
    init({commit}) {
        commit('SET_CRM_METRICS', [])
    },

    async getResources({commit}, projectId) {
        const collects = await axios.get(
            `${process.env.VUE_APP_NODE_API_HOST}/collect?projectId=${projectId}&page=1&limit=250&ascending=0`
        )

        const processes = await axios.get(
            `${process.env.VUE_APP_NODE_API_HOST}/process?projectId=${projectId}&page=1&limit=250&ascending=0&byColumn=1`
        )

        const resources = [...collects.data.data, ...processes.data.data]

        return resources.filter(
            ({subtype}) =>
                subtype.includes('Google Analytics') ||
                subtype.includes('WitCloud Stream') ||
                subtype.includes('Channel Attribution')
        )
    },

    async getCrmFields({commit}, pipelineId) {
        const response = await axios.get(
            `${process.env.VUE_APP_NODE_API_HOST}/crm/getPipelineFields?pipelineId=${pipelineId}`
        )

        return response.data.fields
    },

    async getOfflineFields({commit}, pipelineId) {
        const {
            data: {data},
        } = await axios.get(`${process.env.VUE_APP_NODE_API_HOST}/process/customData/${pipelineId}/list`)

        const allFields = Object.keys(data)
            .map(key => {
                return data[key]
            })
            .flat()

        const uniqueFields = []
        allFields.forEach(field => {
            const foundField = uniqueFields.find(el => el.label === field.label)
            if (!foundField || (foundField && foundField.level !== field.level)) {
                uniqueFields.push(field)
            }
        })

        commit(
            'SET_CRM_METRICS',
            uniqueFields.filter(el => el.label.includes('crmMetric'))
        )
        commit(
            'SET_CRM_DIMENSIONS',
            uniqueFields.filter(el => el.label.includes('crmDimension'))
        )
        commit('SET_ALL_FIELDS', allFields)
        commit('SET_UNIQUE_FIELDS', uniqueFields)
    },
}

const mutations = {
    SET_CRM_METRICS(state, crmMetrics) {
        state.crmMetrics = crmMetrics
    },

    SET_CRM_DIMENSIONS(state, crmDimensions) {
        state.crmDimensions = crmDimensions
    },

    SET_ALL_FIELDS(state, allFields) {
        state.allFields = allFields
    },

    SET_UNIQUE_FIELDS(state, uniqueFields) {
        state.crmDimensions = uniqueFields
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
