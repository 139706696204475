import Vue from 'vue'
import Router from 'vue-router'
// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
const AuthContainer = () => import('@/containers/AuthContainer')
const EmptyContainer = () => import('@/containers/EmptyContainer')

// Views
const Dashboard = () => import('@/views/Dashboard/Index')
const Activity = () => import('@/views/Activity/Index')

const CollectList = () => import('@/views/Collect/List')
const Collectource = () => import('@/views/Collect/Source')

const Analytics4BQMain = () => import('@/views/Collect/Web/Analytics4BQ/Index')
const Analytics4BQForm = () => import('@/views/Collect/Web/Analytics4BQ/Form')

const BingMain = () => import('@/views/Collect/Ads/Bing/Index')
const BingForm = () => import('@/views/Collect/Ads/Bing/Form')

const FacebookMain = () => import('@/views/Collect/Ads/Facebook/Index')
const FacebookForm = () => import('@/views/Collect/Ads/Facebook/Form')

const GoogleAdsMain = () => import('@/views/Collect/Ads/GoogleAds/Index')
const GoogleAdsForm = () => import('@/views/Collect/Ads/GoogleAds/Form')

const CriteoMain = () => import('@/views/Collect/Ads/Criteo/Index')
const CriteoForm = () => import('@/views/Collect/Ads/Criteo/Form')

const TradeDoublerMain = () => import('@/views/Collect/Ads/TradeDoubler/Index')
const TradeDoublerForm = () => import('@/views/Collect/Ads/TradeDoubler/Form')

const RTBMain = () => import('@/views/Collect/Ads/RTB/Index')
const RTBForm = () => import('@/views/Collect/Ads/RTB/Form')

const OptimiseMain = () => import('@/views/Collect/Ads/Optimise/Index')
const OptimiseForm = () => import('@/views/Collect/Ads/Optimise/Form')

const DomodiMain = () => import('@/views/Collect/Ads/Domodi/Index')
const DomodiForm = () => import('@/views/Collect/Ads/Domodi/Form')

const CeneoMain = () => import('@/views/Collect/Ads/Ceneo/Index')
const CeneoForm = () => import('@/views/Collect/Ads/Ceneo/Form')

const TikTokMain = () => import('@/views/Collect/Ads/TikTok/Index')
const TikTokForm = () => import('@/views/Collect/Ads/TikTok/Form')

const AwinMain = () => import('@/views/Collect/Ads/Awin/Index')
const AwinForm = () => import('@/views/Collect/Ads/Awin/Form')

const ConvertiserMain = () => import('@/views/Collect/Ads/Convertiser/Index')
const ConvertiserForm = () => import('@/views/Collect/Ads/Convertiser/Form')

const ShoperMain = () => import('@/views/Collect/Ecommerce/Shoper/Index')
const ShoperForm = () => import('@/views/Collect/Ecommerce/Shoper/Form')

const ShopifyMain = () => import('@/views/Collect/Ecommerce/Shopify/Index')
const ShopifyForm = () => import('@/views/Collect/Ecommerce/Shopify/Form')

const MagentoMain = () => import('@/views/Collect/Ecommerce/Magento/Index')
const MagentoForm = () => import('@/views/Collect/Ecommerce/Magento/Form')

const PrestaShopMain = () => import('@/views/Collect/Ecommerce/PrestaShop/Index')
const PrestaShopForm = () => import('@/views/Collect/Ecommerce/PrestaShop/Form')

const IdoSellMain = () => import('@/views/Collect/Ecommerce/IdoSell/Index')
const IdoSellForm = () => import('@/views/Collect/Ecommerce/IdoSell/Form')

const WooCommerceMain = () => import('@/views/Collect/Ecommerce/WooCommerce/Index')
const WooCommerceForm = () => import('@/views/Collect/Ecommerce/WooCommerce/Form')

const BaselinkerMain = () => import('@/views/Collect/Ecommerce/Baselinker/Index')
const BaselinkerForm = () => import('@/views/Collect/Ecommerce/Baselinker/Form')

const AllegroMain = () => import('@/views/Collect/Marketplace/Allegro/Index')
const AllegroForm = () => import('@/views/Collect/Marketplace/Allegro/Form')

const HubSpotMain = () => import('@/views/Collect/CRM/HubSpot/Index')
const HubSpotForm = () => import('@/views/Collect/CRM/HubSpot/Form')

const SearchConsoleMain = () => import('@/views/Collect/Seo/SearchConsole/Index')
const SearchConsoleForm = () => import('@/views/Collect/Seo/SearchConsole/Form')

const CustomDataMain = () => import('@/views/Collect/CustomData/Index')
const CustomDataForm = () => import('@/views/Collect/CustomData/Form')

const DataStreamMain = () => import('@/views/Collect/Custom/DataStream/Index')
const DataStreamForm = () => import('@/views/Collect/Custom/DataStream/Form')

const CurrencyConvertersMain = () => import('@/views/Collect/CurrencyConverters/Index')
const CurrencyConvertersForm = () => import('@/views/Collect/CurrencyConverters/Form')

const ProcessSource = () => import('@/views/Process/Source')
const ProcessList = () => import('@/views/Process/List')

const WitHookMain = () => import('@/views/Process/WitHooks/Index')
const WitHookCreate = () => import('@/views/Process/WitHooks/Create')
const WitHookDetails = () => import('@/views/Process/WitHooks/Details')

const SaveToStorageMain = () => import('@/views/Process/SaveToStorage/Index')
const SaveToStorageCreate = () => import('@/views/Process/SaveToStorage/Create')
const SaveToStorageDetails = () => import('@/views/Process/SaveToStorage/Details')

const IamPermissionMain = () => import('@/views/Iam/Permission/Index')
const IamPermissionList = () => import('@/views/Iam/Permission/List')
const IamPermissionCreate = () => import('@/views/Iam/Permission/Create')

const IamProjectsMain = () => import('@/views/Iam/Projects/Index')
const IamProjectsList = () => import('@/views/Iam/Projects/List')
const IamProjectsCreate = () => import('@/views/Iam/Projects/Create')
const IamProjectsDetails = () => import('@/views/Iam/Projects/Details')
const IamProjectsWelcome = () => import('@/views/Iam/Projects/Welcome')

const IamTokensList = () => import('@/views/Iam/Tokens/List')
const IamTokensMain = () => import('@/views/Iam/Tokens/Index')

const IamQuotasDefault = () => import('@/views/Iam/Quotas/Default')
const IamQuotasMain = () => import('@/views/Iam/Quotas/Index')

const ReportsDataProvidersMain = () => import('@/views/Reports/DataProviders/Index')
const ReportsDataProvidersList = () => import('@/views/Reports/DataProviders/List')
const ReportsDataProvidersSource = () => import('@/views/Reports/DataProviders/Source')
const ReportDataProvidersForm = () => import('@/views/Reports/DataProviders/Form')
const ReportDataProvidersPreview = () => import('@/views/Reports/DataProviders/Preview/Page')

const ReportsDataStudioMain = () => import('@/views/Reports/DataStudio/Index')
const ReportsDataStudioList = () => import('@/views/Reports/DataStudio/List')

const ReportsQueryJobsCreate = () => import('@/views/Reports/QueryJobs/Create')
const ReportsQueryJobsDetails = () => import('@/views/Reports/QueryJobs/Details')
const ReportsQueryJobsMain = () => import('@/views/Reports/QueryJobs/Index')
const ReportsQueryJobsList = () => import('@/views/Reports/QueryJobs/List')

const CustomQueriesList = () => import('@/views/CustomQueries/List')
const CustomQueriesForm = () => import('@/views/CustomQueries/Form')
const CustomQueriesCategories = () => import('@/views/CustomQueries/Categories')

const Login = () => import('@/views/Auth/Login')
const Register = () => import('@/views/Auth/Register')
const Registered = () => import('@/views/Auth/Registered')
const Invite = () => import('@/views/Auth/Invite')
const ForgotPassword = () => import('@/views/Auth/ForgotPassword')
const ResetPassword = () => import('@/views/Auth/ResetPassword')

const WorkflowForm = () => import('@/views/Workflow/Form')
const WorkflowList = () => import('@/views/Workflow/List')
const WorkflowReport = () => import('@/views/Workflow/Report')

const TutorialDefault = () => import('@/views/Tutorials/Index')

const BillingAccountsDeactivate = () => import('@/views/Billing/Accounts/Deactivate')
const BillingAccountsForm = () => import('@/views/Billing/Accounts/Form')
const BillingAccountsList = () => import('@/views/Billing/Accounts/List')
const BillingAccountsMain = () => import('@/views/Billing/Accounts/Index')
const BillingMain = () => import('@/views/Billing/Index')

const InvoicesList = () => import('@/views/Billing/Invoices/List')
const InvoicesMain = () => import('@/views/Billing/Invoices/Index')

Vue.use(Router)

export default new Router({
    mode: 'hash',
    linkActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/auth',
            name: 'Auth',
            component: AuthContainer,
            meta: {auth: false},
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    meta: {auth: false},
                    component: Login,
                },
                {
                    path: 'forgot-password',
                    name: 'ForgotPassword',
                    meta: {auth: false},
                    component: ForgotPassword,
                },
                {
                    path: 'reset-password',
                    name: 'ResetPassword',
                    meta: {auth: false},
                    component: ResetPassword,
                },
                {
                    path: 'register',
                    name: 'Register',
                    meta: {auth: false},
                    component: Register,
                },
                {
                    path: 'registered',
                    name: 'Registered',
                    meta: {auth: false},
                    component: Registered,
                },
                {
                    path: 'invite/:id',
                    name: 'Invite',
                    meta: {auth: false},
                    component: Invite,
                },
            ],
        },
        {
            path: '/iam/projects',
            redirect: '/iam/projects/list',
            name: 'Iam',
            component: DefaultContainer,
            meta: {auth: true, noProjectRequired: true},
            children: [
                {
                    path: 'create',
                    name: 'Create',
                    component: IamProjectsCreate,
                    meta: {auth: true, noProjectRequired: true},
                },
                {
                    path: ':id',
                    name: 'Details',
                    component: IamProjectsDetails,
                    meta: {auth: true, noProjectRequired: true},
                },
            ],
        },
        {
            path: '/p/:projectId',
            redirect: '/p/:projectId/dashboard',
            component: DefaultContainer,
            meta: {auth: true, project: true},
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta: {auth: true},
                },
                {
                    path: 'activity',
                    name: 'Activity',
                    component: Activity,
                    meta: {auth: true},
                },
                {
                    path: 'collect',
                    name: 'Collect',
                    component: EmptyContainer,
                    meta: {auth: true},
                    redirect: 'collect/',
                    children: [
                        {
                            path: '/',
                            name: 'List',
                            component: CollectList,
                            meta: {auth: true},
                        },
                        {
                            path: 'source',
                            name: 'Create',
                            component: Collectource,
                            meta: {auth: true},
                        },
                        {
                            path: 'analytics4bq',
                            name: 'Analytics4bq',
                            component: Analytics4BQMain,
                            redirect: 'source',
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: Analytics4BQForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: Analytics4BQForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'bing',
                            name: 'Bing',
                            redirect: 'source',
                            component: BingMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: BingForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: BingForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'facebook',
                            name: 'Facebook',
                            redirect: 'source',
                            component: FacebookMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: FacebookForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: FacebookForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'googleads',
                            name: 'Google Ads',
                            redirect: 'source',
                            component: GoogleAdsMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: GoogleAdsForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: GoogleAdsForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'tradedoubler',
                            name: 'Tradedoubler',
                            redirect: 'source',
                            component: TradeDoublerMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: TradeDoublerForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: TradeDoublerForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'shoper',
                            name: 'Shoper',
                            redirect: 'source',
                            component: ShoperMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: ShoperForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: ShoperForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'shopify',
                            name: 'Shopify',
                            redirect: 'source',
                            component: ShopifyMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: ShopifyForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: ShopifyForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'magento',
                            name: 'Magento',
                            redirect: 'source',
                            component: MagentoMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: MagentoForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: MagentoForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'prestashop',
                            name: 'PrestaShop',
                            redirect: 'source',
                            component: PrestaShopMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: PrestaShopForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: PrestaShopForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'idosell',
                            name: 'IdoSell',
                            redirect: 'source',
                            component: IdoSellMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: IdoSellForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: IdoSellForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'woocommerce',
                            name: 'WooCommerce',
                            redirect: 'source',
                            component: WooCommerceMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: WooCommerceForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: WooCommerceForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'baselinker',
                            name: 'Baselinker',
                            redirect: 'source',
                            component: BaselinkerMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: BaselinkerForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: BaselinkerForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'criteo',
                            name: 'Criteo',
                            redirect: 'source',
                            component: CriteoMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: CriteoForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: CriteoForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'rtb',
                            name: 'RTB',
                            redirect: 'source',
                            component: RTBMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: RTBForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: RTBForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'optimise',
                            name: 'Optimise',
                            redirect: 'source',
                            component: OptimiseMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: OptimiseForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: OptimiseForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'domodi',
                            name: 'Domodi',
                            redirect: 'source',
                            component: DomodiMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: DomodiForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: DomodiForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'awin',
                            name: 'Awin',
                            redirect: 'source',
                            component: AwinMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: AwinForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: AwinForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'convertiser',
                            name: 'Convertiser',
                            redirect: 'source',
                            component: ConvertiserMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: ConvertiserForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: ConvertiserForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'ceneo',
                            name: 'Ceneo',
                            redirect: 'source',
                            component: CeneoMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: CeneoForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: CeneoForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'tiktok',
                            name: 'TikTok',
                            redirect: 'source',
                            component: TikTokMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: TikTokForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: TikTokForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'allegro',
                            name: 'Allegro',
                            redirect: 'source',
                            component: AllegroMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: AllegroForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: AllegroForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'hubspot',
                            name: 'HubSpot',
                            redirect: 'source',
                            component: HubSpotMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: HubSpotForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: HubSpotForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'searchConsole',
                            name: 'Search Console',
                            redirect: 'source',
                            component: SearchConsoleMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: SearchConsoleForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: SearchConsoleForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'customData',
                            name: 'Custom Data',
                            component: CustomDataMain,
                            redirect: 'source',
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: CustomDataForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: CustomDataForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'datastream',
                            name: 'Data Stream',
                            component: DataStreamMain,
                            redirect: 'source',
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: DataStreamForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: DataStreamForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'currencyConverters',
                            name: 'Currency Converters',
                            component: CurrencyConvertersMain,
                            redirect: 'source',
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: CurrencyConvertersForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: CurrencyConvertersForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'process',
                    name: 'Process',
                    component: EmptyContainer,
                    meta: {auth: true},
                    redirect: 'process/',
                    children: [
                        {
                            name: 'List',
                            path: '/',
                            component: ProcessList,
                            meta: {auth: true},
                        },
                        {
                            path: 'source',
                            name: 'Create',
                            component: ProcessSource,
                            meta: {auth: true},
                        },
                        {
                            path: 'withooks',
                            name: 'WitHooks',
                            redirect: 'source',
                            component: WitHookMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: WitHookCreate,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: WitHookDetails,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'savetostorage',
                            name: 'Save To Storage',
                            redirect: 'source',
                            component: SaveToStorageMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: SaveToStorageCreate,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: SaveToStorageDetails,
                                    meta: {auth: true},
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'reports',
                    name: 'Smart Data',
                    component: EmptyContainer,
                    redirect: 'reports/dataproviders/list',
                    meta: {auth: true},
                    children: [
                        {
                            path: 'dataproviders',
                            redirect: 'dataproviders/source',
                            name: 'Reports',
                            component: ReportsDataProvidersMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'list',
                                    name: 'List',
                                    component: ReportsDataProvidersList,
                                    meta: {auth: true},
                                },
                                {
                                    path: 'source',
                                    name: 'Create',
                                    component: ReportsDataProvidersSource,
                                    meta: {auth: true},
                                },
                                {
                                    path: 'create/:customQueryId',
                                    name: 'Create',
                                    component: ReportDataProvidersForm,
                                    meta: {auth: true},
                                },

                                {
                                    path: 'preview/:id',
                                    name: 'Preview',
                                    component: ReportDataProvidersPreview,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: ReportDataProvidersForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'datastudio',
                            redirect: 'datastudio/list',
                            name: 'Data Studio',
                            component: ReportsDataStudioMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'list',
                                    name: 'List',
                                    component: ReportsDataStudioList,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'queryjobs',
                            redirect: 'queryjobs/list',
                            name: 'Query Jobs',
                            component: ReportsQueryJobsMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: ReportsQueryJobsCreate,
                                    meta: {auth: true},
                                },
                                {
                                    path: 'list',
                                    name: 'List',
                                    component: ReportsQueryJobsList,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: ReportsQueryJobsDetails,
                                    meta: {auth: true},
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'iam',
                    redirect: 'iam/permission/list',
                    name: 'Iam',
                    component: EmptyContainer,
                    meta: {auth: true},
                    children: [
                        {
                            path: 'permission',
                            redirect: 'permission/list',
                            name: 'Permission',
                            component: IamPermissionMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'list',
                                    name: 'List',
                                    component: IamPermissionList,
                                    meta: {auth: true},
                                },
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: IamPermissionCreate,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'projects',
                            redirect: 'projects/list',
                            name: 'Projects',
                            component: IamProjectsMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: 'list',
                                    name: 'List',
                                    component: IamProjectsList,
                                    meta: {auth: true},
                                },
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: IamProjectsCreate,
                                    meta: {auth: true},
                                },
                                {
                                    path: 'welcome',
                                    name: 'Welcome',
                                    component: IamProjectsWelcome,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: IamProjectsDetails,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'tokens',
                            name: 'Tokens',
                            component: IamTokensMain,
                            redirect: 'tokens/',
                            meta: {auth: true},
                            children: [
                                {
                                    path: '/',
                                    name: 'List',
                                    component: IamTokensList,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'quotas',
                            name: 'Quotas',
                            component: IamQuotasMain,
                            redirect: 'quotas/',
                            meta: {auth: true},
                            children: [
                                {
                                    path: '/',
                                    name: 'Details',
                                    component: IamQuotasDefault,
                                    meta: {auth: true},
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'billing',
                    name: 'Billing',
                    component: EmptyContainer,
                    redirect: 'billing',
                    meta: {auth: true},
                    children: [
                        {
                            path: '/',
                            name: 'Billing',
                            component: BillingMain,
                            meta: {auth: true},
                        },
                        {
                            path: 'deactivate/:id',
                            name: 'Deactivate',
                            component: BillingAccountsDeactivate,
                            meta: {auth: true},
                        },
                        {
                            path: 'accounts',
                            name: 'Billing Accounts',
                            redirect: 'accounts/',
                            component: BillingAccountsMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: '/',
                                    name: 'List',
                                    component: BillingAccountsList,
                                    meta: {auth: true},
                                },
                                {
                                    path: 'create',
                                    name: 'Create',
                                    component: BillingAccountsForm,
                                    meta: {auth: true},
                                },
                                {
                                    path: 'deactivate/:id',
                                    name: 'Deactivate',
                                    component: BillingAccountsDeactivate,
                                    meta: {auth: true},
                                },
                                {
                                    path: ':id',
                                    name: 'Details',
                                    component: BillingAccountsForm,
                                    meta: {auth: true},
                                },
                            ],
                        },
                        {
                            path: 'invoices',
                            name: 'Invoices',
                            redirect: 'invoices/',
                            component: InvoicesMain,
                            meta: {auth: true},
                            children: [
                                {
                                    path: '/',
                                    name: 'List',
                                    component: InvoicesList,
                                    meta: {auth: true},
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'customqueries',
                    name: 'Custom Queries',
                    component: EmptyContainer,
                    redirect: 'customqueries/',
                    meta: {auth: true},
                    beforeEnter: (to, from, next) => {
                        if (/[^@]+@witbee+\.(?:com|pl)/.test(Vue.auth.user().email)) next()
                        else Vue.router.push('/dashboard')
                    },
                    children: [
                        {
                            path: '/',
                            name: 'List',
                            component: CustomQueriesList,
                            meta: {auth: true},
                        },
                        {
                            path: 'create',
                            name: 'Create',
                            component: CustomQueriesForm,
                            meta: {auth: true},
                        },
                        {
                            path: 'categories',
                            name: 'Categories',
                            component: CustomQueriesCategories,
                            meta: {auth: true},
                        },
                        {
                            path: ':id',
                            name: 'Details',
                            component: CustomQueriesForm,
                            meta: {auth: true},
                        },
                    ],
                },
                {
                    path: 'workflow',
                    name: 'Workflow',
                    component: EmptyContainer,
                    redirect: 'workflow/',
                    meta: {auth: true},
                    children: [
                        {
                            path: '/',
                            name: 'List',
                            component: WorkflowList,
                            meta: {auth: true},
                        },
                        {
                            path: 'create',
                            name: 'Create',
                            component: WorkflowForm,
                            meta: {auth: true},
                        },
                        {
                            path: ':id',
                            name: 'Details',
                            component: WorkflowForm,
                            meta: {auth: true},
                        },
                        {
                            path: 'report/:id',
                            name: 'Report',
                            component: WorkflowReport,
                            meta: {auth: true},
                        },
                    ],
                },
                {
                    path: 'tutorials',
                    name: 'Tutorials',
                    component: EmptyContainer,
                    redirect: 'dashboard',
                    meta: {auth: true},
                    children: [
                        {
                            path: ':slug',
                            name: 'Tutorial',
                            component: TutorialDefault,
                            meta: {auth: true},
                        },
                    ],
                },
            ],
        },
        {
            path: '/welcome',
            component: DefaultContainer,
            meta: {auth: true},
            children: [
                {
                    path: '/',
                    name: 'Welcome',
                    component: IamProjectsWelcome,
                    meta: {auth: true, noProjectRequired: true},
                },
            ],
        },
    ],
})
